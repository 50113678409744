

















































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Forecast from '@/models/Forecast'

@Component({
  components: {
    Widget,
  },
})
export default class ForecastUpdate extends Vue {
  public file: any = null;

  public loading: boolean = false;

  public forecast: Forecast = new Forecast();

  public cancel() {
    this.$router.push({ name: 'ForecastHome' })
  }

  public onSubmit() {
    this.loading = true

    this.forecast
      .update(this.file)
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'ForecastHome' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }
}
